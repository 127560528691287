<template>
  <div>
    <b-row class="card">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-pen"></i> Filtros
        </h5>
      </div>

      <div class="w-100 p-3">
        <b-row>
          <b-col lg="2">
            <b-form-group label="Cód. Produto">
              <b-form-input v-model="filters.codigo"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group label="Inversor">
              <b-form-input v-model="filters.inverter"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group label="Placa">
              <b-form-input v-model="filters.panel"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group label="Potência kit">
              <b-form-input v-model="filters.power"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Estrutura">
              <b-form-select :options="structureOptions" v-model="filters.structure"/>
            </b-form-group>
          </b-col>
          <b-col lg="4" class="align-btn">
            <b-button variant="info" class="mr-2" @click="getList(true)">Pesquisar</b-button>
            <b-button variant="danger">Limpar</b-button>
          </b-col>
        </b-row>
      </div>
    </b-row>
    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-solar-panel"></i> Porcentagem sobre as vendas
        </h5>
      </div>
      <div class="w-100 p-3">
        <b-row>
          <!-- <b-col lg="4">
            <b-form-group label="(%) Sobre os produtos">
              <div class="d-flex">
                <b-form-input class="w-50" v-model="config.percentage_product" :disabled="!edit_product"></b-form-input>
                <b-button class="ml-2"
                v-if="!edit_product"
                @click="() => edit_product = !edit_product"
                variant="warning">
                  <i class="fas fa-pen"></i> Alterar
                </b-button>
                <b-button class="ml-2"
                v-else
                @click="save"
                variant="primary">
                  <i class="fas fa-save"></i> Salvar
                </b-button>
              </div>
            </b-form-group>
          </b-col> -->
          <b-col lg="4">
            <b-form-group label="(%) Financiamento">
              <div class="d-flex">
                <b-form-input class="w-50" v-model="config.percentage_financing" :disabled="!edit_financing"></b-form-input>
                <b-button class="ml-2"
                v-if="!edit_financing"
                @click="() => edit_financing = !edit_financing"
                variant="warning">
                  <i class="fas fa-pen"></i> Alterar
                </b-button>
                <b-button class="ml-2"
                v-else
                @click="save"
                variant="primary">
                  <i class="fas fa-save"></i> Salvar
                </b-button>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
       </div>
      <div class="w-100 p-3">
        <b-table
        responsive
        bordered
        :items="provinces"
        :fields="columTableProvinces">
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>
          <!-- <template v-slot:cell(percentage_sell)="data">
            <div class="d-flex justify-content-between">
              <b-form-group style="width: 90px">
                <b-input-group append="%">
                  <b-input
                    v-model="data.item.percentage_sell"
                  />
                </b-input-group>
              </b-form-group>
              <div>
                <b-button variant="primary" @click="save(data.item)">
                  <i class="fas fa-save"></i>
                  Salvar
                </b-button>
              </div>
            </div>
          </template> -->

          <template v-slot:cell(actions)="data">
            <div class="d-flex justify-content-center">
              <b-button variant="warning" class="mr-2" @click="setItemEdit(data.item)">
                <i class="fas fa-edit"></i>
                Editar
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
    </b-row>

    <b-modal :title="formFields.name" id="edit-percentage">
      <b-form>
        <b-row>
          <b-col lg="4" v-for="(form, index) in formFields.panels" :key="index">
            <b-form-group :label="form.panel.brand.toUpperCase()">
              <b-input-group append="%">
                <b-form-input v-model="form.percentage_sale"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('edit-percentage')">Cancelar</b-button>
        <b-button variant="primary" @click="save(formFields)">Salvar</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import HelperService from '@/Services/HelperService';
import ConfigAdminService from '@/Services/ConfigAdminService';
import MessagesMixin from '@/mixins/messages-mixin';

export default {
  data() {
    return {
      columTableProvinces: ConfigAdminService.getFieldsTableProvinces(),
      products: [],
      formFields: [],
      edit_product: false,
      edit_sale: false,
      edit_financing: false,
      provinces: [],
      structureOptions: [
        {
          text: 'Selecione',
          value: '',
        },
        {
          text: 'Colonial',
          value: 'colonial',
        },
        {
          text: 'Paraf Madeira',
          value: 'madeira',
        },
        {
          text: 'Paraf Metal',
          value: 'metal',
        },
        {
          text: 'Trapezoidal',
          value: 'trapezoidal',
        },
        {
          text: 'Solo',
          value: 'solo',
        },
      ],
      filters: {
        codigo: '',
        inverter: '',
        panel: '',
        power: '',
        structure: '',
      },
      config: {
        id: '',
        percentage_sale: '',
        percentage_product: '',
        percentage_financing: '',
      },
    };
  },
  mixins: [MessagesMixin],
  methods: {
    clearFilters() {
      this.filters = {
        codigo: '',
        inverter: '',
        panel: '',
        power: '',
        structure: '',
      };

      this.getList();
    },

    setItemEdit(item) {
      this.formFields = {
        ...item,
      };
      this.$bvModal.show('edit-percentage');
    },

    async getProvinces() {
      try {
        const { data } = await HelperService.getProvinces();
        const panels = data[0].province_panels;

        panels.forEach((item) => {
          this.columTableProvinces.push({
            key: item.panel.brand.toLowerCase(),
            label: item.panel.brand.toUpperCase(),
            thClass: 'text-center',
            tdClass: 'text-center',
          });
        });

        this.columTableProvinces.push({
          key: 'actions',
          label: 'Ações',
          thClass: 'text-center',
          tdClass: 'text-center',
        });

        data.forEach((item) => {
          const province = {
            id: item.id,
            name: item.name,
            percentage_sell: parseFloat((item.percentage_sell * 100).toFixed(2)),
            panels: [],
          };

          item.province_panels.forEach((panel) => {
            province[panel.panel.brand.toLowerCase()] = parseFloat((panel.percentage_sale * 100).toFixed(2));
            province.panels.push({
              ...panel,
              percentage_sale: parseFloat((panel.percentage_sale * 100).toFixed(2)),
            });
          });

          this.provinces.push(province);
        });
      } catch (error) {
        console.log(error);
      }
    },

    async save(item) {
      const payload = [];
      item.panels.forEach((panel) => {
        payload.push({
          province_id: item.id,
          panel_id: panel.panel.id,
          percentage_sale: panel.percentage_sale / 100,
        });
      });

      try {
        const { data } = await ConfigAdminService.updatePercentageSell(payload, item.id);
        this.$bvModal.hide('edit-percentage');
        this.messageSuccess(`A procentagem do estado ${data.name} foi atualizada com sucesso!`);
        this.provinces = [];
        this.getProvinces();
      } catch (error) {
        console.log(error);
      }
    },
    desactiveAll() {
      this.edit_product = false;
      this.edit_sale = false;
      this.edit_financing = false;
    },
  },
  created() {
    this.getProvinces();
  },
};
</script>
<style lang="css">
#tb-products td {
  font-size: 14px !important;
}
</style>
