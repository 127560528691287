/* eslint-disable class-methods-use-this */
import http from '@/http';
import Service from './Service';

class ConfigAdminService extends Service {
  constructor() {
    super('config-admin');
  }

  updatePercentageSell(data, id) {
    return http.put(`config-admin/percentage/${id}`, data);
  }

  getFieldsTableProvinces() {
    return [
      {
        key: 'name',
        label: 'Estado',
      },
    ];
  }
}

const configAdminService = new ConfigAdminService();
export default configAdminService;
